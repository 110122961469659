.chat-services {
  transition: 0.5s;
  max-height: 0;
}
.chat-services.expand {
  max-height: 350px;
}
.message {
  max-width: 250px;
}

.chat-modal {
  transition: 0.5s;
  opacity: 0;
  transform: translateX(500px);
}

.chat-modal.show {
  opacity: 1;
  transform: translateX(0);
}

@keyframes animateModal {
  from {
    transform: translateX(200px) opacity(0);
  }
  to {
    transform: translateX(0) opacity(1);
  }
}
